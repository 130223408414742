/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import "./layout.css"
import Header from "./header"

const Layout = ({ children }) => {

  return (
    <>
      <div className="background-wrapper">
        <Header />
        <main>
          {children}
        </main>
        <footer>
          © {new Date().getFullYear()}{" "}David Sapp
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
