import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => (
  <header>
    <div className="header-wrapper">
      <Link to="/me" className="header-nav">ME</Link>
      <Link to="/notes" className="header-nav">NOTES</Link>
      <Link to="/work" className="header-nav">WORK</Link>
      <Link to="/contact" className="header-nav">MAIL</Link>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
